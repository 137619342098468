const apiRefMessages = {
  parameterOptional: 'optional',
  parameterRequired: 'Required',
  queryParamTitle: 'QUERY PARAMETERS',
  pathParamTitle: 'PATH PARAMETERS',
  bodyParamTitle: 'BODY PARAMETER',
  headerParamTitle: 'HEADER PARAMETER',
  defaultTitle: 'Default : ',
  enumTitle: 'Enum : ',
  request: 'REQUEST',
  response: 'RESPONSE',
  openArrayType:'Array [ ',
  openObjectType:'object {',
  closeArray:'] ',
  closeObject:'} ',
  stringType:'string',
  securitySchemeType: 'Security Scheme Type',
  headerParameterName : 'Header Parameter Name',
  responseParamTitle: 'RESPONSE',
};

export default apiRefMessages;
